import NotepadPrimary from "./notepad.primary.svg";
import NotepadAccent from "./notepad.accent.svg";
import NotepadDark from "./notepad.dark.svg";

import MobilePrimary from "./mobile.primary.svg";
import MobileAccent from "./mobile.accent.svg";
import MobileDark from "./mobile.dark.svg";

import Mobile2Primary from "./mobile2.primary.svg";
import Mobile2Accent from "./mobile2.accent.svg";
import Mobile2Dark from "./mobile2.dark.svg";

import TabletPrimary from "./tablet.primary.svg";
import TabletAccent from "./tablet.accent.svg";
import TabletDark from "./tablet.dark.svg";

import LaptopPrimary from "./laptop.primary.svg";
import LaptopAccent from "./laptop.accent.svg";
import LaptopDark from "./laptop.dark.svg";

export default {
  Notepad: {
    Primary: NotepadPrimary,
    Accent: NotepadAccent,
    Dark: NotepadDark,
    ratio: 2 / 3,
    widthMod: 0.7235984355,
    inset: {
      top: "3%",
      left: "6%",
      right: "3%",
      bottom: "3%",
    },
  },
  Mobile: {
    Primary: MobilePrimary,
    Accent: MobileAccent,
    Dark: MobileDark,
    ratio: 1 / 2,
    widthMod: 0.510968919,
    inset: {
      top: "8%",
      left: "8%",
      right: "8%",
      bottom: "5%",
    },
  },
  Mobile2: {
    Primary: Mobile2Primary,
    Accent: Mobile2Accent,
    Dark: Mobile2Dark,
    ratio: 6 / 13,
    widthMod: 0.4809862098,
    inset: {
      top: "8%",
      left: "9%",
      right: "9%",
      bottom: "4%",
    },
    offset: {
      bottom: "-7%",
    },
    objectPosition: "center",
  },
  Tablet: {
    Primary: TabletPrimary,
    Accent: TabletAccent,
    Dark: TabletDark,
    ratio: 2 / 3,
    widthMod: 0.7063899204,
    inset: {
      top: "5%",
      left: "6%",
      right: "6%",
      bottom: "5%",
    },
  },
  Laptop: {
    Primary: LaptopPrimary,
    Accent: LaptopAccent,
    Dark: LaptopDark,
    ratio: 16 / 10,
    widthMod: 1.8596830468,
    align: "left",
    offset: {
      bottom: "-5px",
    },
    inset: {
      top: "5%",
      left: "14%",
      right: "14%",
      bottom: "11%",
    },
  },
};
